import { fill2Zero } from "utils/str";

export const getTimeRange = () => {
  const range = [];
  for (var h = 0; h < 24; h++) {
    for (var m = 0; m < 60; m += 15) {
      let time = fill2Zero(h) + ":" + fill2Zero(m);
      range.push({ value: time, label: time });
    }
  }
  return range;
};

export const getDefaultTimezone = () => {
  return Intl ? Intl.DateTimeFormat().resolvedOptions().timeZone : '';
};
