import React, { useState, useContext } from 'react'
import { useForm, Controller } from "react-hook-form";
import { matchPath } from 'react-router';
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next';

import { faEnvelope, faLock } from '@fortawesome/free-solid-svg-icons'

import FormPanel from 'components/form/FormPanel'
import FormSubmit from 'components/form/FormSubmit'
import FormAlert from 'components/form/FormAlert'
import ControlText from 'components/form/ControlText'
import FormLinks from 'components/form/FormLinks'

import { yupResolver } from '@hookform/resolvers/yup';

import { setSubmitButtonState } from 'utils/form'
import { setUserLoggedIn } from 'utils/auth'
import { catchFormErrors } from 'utils/error'

import { useMutation } from '@apollo/client'
import { SIGNIN, /*SIGNIN_FACEBOOK*/ } from 'graphql/mutation/signin'

import { routes, routesExclusions } from 'routes'
import { getRoute } from 'utils/route';
import getSchema from './SigninForm.schema'
import { UserContext } from 'context/UserContext';

const getUserUrl = (userClubs) => {
    if (userClubs.length == 1) {
        return getRoute('club', { slug: userClubs[0].slug })
    } else if (userClubs.length > 1) {
        return getRoute('clubs')
    }
    return getRoute('home');
}

const isExcludedUrl = (url) => {
    return routesExclusions.filter(item =>
        matchPath({
            path: item,
        }, url)
    ).length > 0;
}

const getFinalUrl = (userClubs) => {
    const lastRequiredUrl = localStorage.getItem('last-required-page')
    localStorage.removeItem('last-required-page')
    
    if (!lastRequiredUrl) {
        return getUserUrl(userClubs);
    }
    if (!isExcludedUrl(lastRequiredUrl)) {
        return lastRequiredUrl;
    }

    return getRoute('home');
}


export const SigninForm = () => {

    const { t } = useTranslation(['translation', 'errors', 'forms'])
    const navigateTo = useNavigate()

    const defaultLogin = localStorage.getItem('last-login') ?? ''

    const [isLoading, setIsLoading] = useState(false)
    const [hasSubmitError, setHasSubmitError] = useState(false)
    const [message, setMessage] = useState('')

    const { setIsConnected, setPicture, setFirstname, setHasWorkspace, setClubCount  } = useContext(UserContext)

    const schema = getSchema(t)

    const [signinUser] = useMutation(SIGNIN, {
        onCompleted({ Signin }) {
            setIsLoading(false)
            
            setIsConnected(true)
            setFirstname(Signin.firstname)
            setPicture(Signin.picture)
            setHasWorkspace(Signin.has_workspace)
            setClubCount(Signin.Clubs.length)

            setUserLoggedIn(watchLogin, Signin)
            if (Signin.Clubs.length <= 0) {
                navigateTo(getRoute('onboard'))
            } else {
                navigateTo(getFinalUrl(Signin.Clubs));
            }

        },
        onError(error) {
            setIsLoading(false)
            catchFormErrors({
                t,
                errorPath: 'signin',
                defaultMessagePath: 'errors:messages.form_has_errors',
                error,
                setError,
                setMessage
            })
        }
    })

    const {
        handleSubmit,
        control,
        errors,
        clearErrors,
        setError,
        watch
    } = useForm({
        resolver: yupResolver(schema)
    });

    const watchLogin = watch('login')

    const onSubmit = ({ login, password }) => {
        setIsLoading(true)
        setMessage('')
        clearErrors()

        signinUser({
            variables: {
                login,
                password
            },
            context: {
                isAnonymous: true
            }
        })
    }

    const onError = () => {
        setMessage(t('errors:messages.form_has_errors'))
        setSubmitButtonState(setHasSubmitError)
    }

    return (
        <FormPanel title="%forms:signin.title%">
            <form onSubmit={handleSubmit(onSubmit, onError)}>

                <FormAlert>{message}</FormAlert>

                <Controller
                    control={control}
                    name="login"
                    defaultValue={defaultLogin}
                    render={({ onChange, value }) => (
                        <ControlText
                            onChange={onChange}
                            icon={faEnvelope}
                            error={errors.login?.message}
                            type="email"
                            value={value}
                            placeholder={t('forms:signin.fields.login.placeholder')}
                            autoFocus={defaultLogin.length <= 0}
                        />
                    )}
                />

                <Controller
                    control={control}
                    name="password"
                    defaultValue=""
                    render={({ onChange, value }) => (
                        <ControlText
                            onChange={onChange}
                            icon={faLock}
                            error={errors.password?.message}
                            type="password"
                            value={value}
                            placeholder={t('forms:signin.fields.password.placeholder')}
                            autoFocus={defaultLogin.length > 0}
                        />
                    )}
                />

                <FormSubmit isLoading={isLoading} hasSubmitError={hasSubmitError}>{t('forms:signin.btn_submit')}</FormSubmit>

                <FormLinks
                    link1={routes.signup}
                    txt1="%forms:signin.signup_link%"
                    link2={routes.password_forgot}
                    txt2="%forms:signin.forgot_password_link%"
                />
            </form>
        </FormPanel>
    )
}