import React, { useContext } from 'react'
import { Link } from 'react-router-dom'
import { getRoute } from 'utils/route'

import { UserContext } from "context/UserContext";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCog, faUser, faDumbbell, faCalendarDays, faMagnifyingGlass } from '@fortawesome/free-solid-svg-icons'

function Footer() {

    const { isConnected, hasWorkspace } = useContext(UserContext)

    if (!isConnected) return null;

    return (
        <div id="footer" className="is-hidden-desktop">
            <div className="columns is-mobile">
            <div className="column has-text-centered">
                    <Link to={getRoute("my_sessions")}>
                        <FontAwesomeIcon icon={faDumbbell} />
                    </Link>
                </div>
                <div className="column has-text-centered">
                    <Link to={getRoute("clubs")}>
                        <FontAwesomeIcon icon={faCalendarDays} />
                    </Link>
                </div>
                <div className="column has-text-centered">
                    <Link to={getRoute("club_search")}>
                        <FontAwesomeIcon icon={faMagnifyingGlass} />
                    </Link>
                </div>
                <div className="column has-text-centered">
                    <Link to={getRoute("profil")}>
                        <FontAwesomeIcon icon={faUser} />
                    </Link>
                </div>
                {hasWorkspace && <div className="column has-text-centered">
                    <Link to={getRoute("admin_home")}>
                        <FontAwesomeIcon icon={faCog} />
                    </Link>
                </div>}
            </div>
        </div>
    )
}

export default Footer