import React, { useReducer } from 'react';
import PropTypes from 'prop-types'
import { useTranslation, Trans } from 'react-i18next';
import { Link } from "react-router-dom";

import PageHeader from 'structure/PageHeader'
import PageBody from 'structure/PageBody'
import { translate } from 'utils/lang'
import MessagePopin from 'components/MessagePopin'
import { MessagePopinContext, MessagePopinState, MessagePopinReducer } from 'context/MessagePopinContext';
import { Helmet } from 'react-helmet-async';

const Page = ({ header, body, children, title, img, linkTo, linkMsg, isWide, isTransparent, metaTitle }) => {

    const { t } = useTranslation(['pages']);
    const [state, dispatch] = useReducer(MessagePopinReducer, MessagePopinState)

    title = translate(t, title)
    linkMsg = translate(t, linkMsg)
    metaTitle = metaTitle && translate(t, metaTitle)

    // body if surrounded with %
    if (body && (typeof body === 'string') && body.charAt(0) == '%' && body.slice(-1) == '%') {
        body = body.slice(1, -1)
        body = <Trans t={t}>{body}</Trans>
    }

    return (
        <>
        
        { metaTitle && <Helmet><title>{metaTitle}</title></Helmet> }

        <MessagePopinContext.Provider value={{ state, dispatch }}>
            <MessagePopin />

            <div className={"page" + (isWide ? " page-wide" : "") + (isTransparent ? " page-transparent" : "")}>
                
                {(title && !header) && <PageHeader>{title}</PageHeader>}
                { header && header}
                
                <PageBody>{body ? body : children}</PageBody>

                {linkTo && <Link className="button is-primary is-medium" to={linkTo}>{linkMsg}</Link>}

                {img && <img src={img} />}
            </div>

        </MessagePopinContext.Provider>
        </>
    )
}

Page.defaultProps = {
    isWide: false,
    isTransparent: false
};

Page.propTypes = {
    children: PropTypes.any,
    title: PropTypes.string,
    metaTitle: PropTypes?.string,
    header: PropTypes.any,
    body: PropTypes.any,
    img: PropTypes.string,
    linkTo: PropTypes.string,
    linkMsg: PropTypes.string,
    isWide: PropTypes.bool,
    isTransparent: PropTypes.bool
}

export default Page;