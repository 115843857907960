import React from 'react'

export const MessagePopinState = {
    message: '',
    type: ''
}

export const MessagePopinContext = React.createContext(MessagePopinState)

export const MessagePopinReducer = (state, action) => {

    switch (action.type) {
        case 'success':
            return {
                'type': action.type,
                'message': action.message
            }
        case 'info':
            return {
                'type': action.type,
                'message': action.message
            }
        case 'warning':
            return {
                'type': action.type,
                'message': action.message
            }
        case 'danger':
            return {
                'type': action.type,
                'message': action.message
            }
        default:
            return state
    }
}