import React from 'react'
import PropTypes from 'prop-types'
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import ItemIcon from 'components/menu/ItemIcon'

import { getRoute } from "utils/route";

const NavbarItem = ({ title, url, onClick, icon, mobileOnly, desktopOnly, className }) => {

    const { t } = useTranslation();
    const classMobile = mobileOnly ? " is-hidden-tablet is-flex-mobile" : ""
    const classDesktop = desktopOnly ? " is-hidden-mobile is-flex-desktop" : ""

    const getItem = () => {
        if (url) {
            return (<Link
                onClick={() => onClick && onClick()}
                className={"navbar-item " + className + classMobile + classDesktop}
                to={getRoute(url)}
            >
                {icon && <ItemIcon icon={icon} />} <span className="ml-1">{t(title)}</span>
            </Link>)

        } else {
            return (<div
                onClick={() => onClick && onClick()}
                className={"navbar-item is-clickable " + className + classMobile + classDesktop}
            >
                {icon && <ItemIcon icon={icon} />} <span className="ml-1">{t(title)}</span>
            </div>)
        }
    }

    return getItem()
}

NavbarItem.defaultProps = {
    className: ""
}

NavbarItem.propTypes = {
    title: PropTypes.string,
    url: PropTypes.string,
    onClick: PropTypes.func,
    icon: PropTypes.any,
    mobileOnly: PropTypes.bool,
    desktopOnly: PropTypes.bool,
    className: PropTypes.string
}
export default NavbarItem;