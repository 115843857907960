import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next';
import { hasUpperCase, hasLowerCase, hasNumber, hasLength } from 'utils/str'

const PasswordChecker = ({password}) => {

    const { t } = useTranslation();
    const successClass = 'is-success'

    return (
        <Fragment>
            <div className="tags">
                <span className={'tag ' + (hasUpperCase(password) ? successClass : '')}>{t('password_rules.uppercase')}</span>
                <span className={'tag ' + (hasLowerCase(password) ? successClass : '')}>{t('password_rules.lowercase')}</span>
                <span className={'tag ' + (hasNumber(password) ? successClass : '')}>{t('password_rules.number')}</span>
                <span className={'tag ' + (hasLength(password, 8) ? successClass : '')}>{t('password_rules.length')}</span>
            </div>
        </Fragment>
    )
}

PasswordChecker.propTypes = {
    password: PropTypes.string
}

export default PasswordChecker