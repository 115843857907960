import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next';
import { useMutation } from '@apollo/client'

import { useForm, Controller } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';

import FormSubmit from 'components/form/FormSubmit'
import FormAlert from 'components/form/FormAlert'
import ControlText from 'components/form/ControlText'
import ControlTextarea from 'components/form/ControlTextarea'

import { catchFormErrors } from 'utils/error'
import { setSubmitButtonState } from 'utils/form'

import getSchema from './ContactForm.schema'

import { CONTACT_PUBLIC, CONTACT_AUTHENTIFIED } from 'graphql/mutation/contact';

const ContactForm = ({ onMessageSent, authentified }) => {

    const { t } = useTranslation(['translation', 'pages', 'errors']);

    const schema = getSchema(authentified)

    const [isLoading, setIsLoading] = useState(false);
    const [hasSubmitError, setHasSubmitError] = useState(false)
    const [errorMessage, setErrorMessage] = useState('')


    const catchError = (error) => {
        setIsLoading(false)
        catchFormErrors({
            t,
            errorPath: 'contact',
            defaultMessagePath: 'errors:messages.form_has_errors',
            error,
            setError,
            setMessage: setErrorMessage
        })
    }

    const [contactPublic] = useMutation(CONTACT_PUBLIC, {
        onCompleted() {
            setIsLoading(false);
            onMessageSent && onMessageSent();
        },
        onError(error) {
            catchError(error)
        }
    })

    const [contactAuthentified] = useMutation(CONTACT_AUTHENTIFIED, {
        onCompleted() {
            setIsLoading(false);
            onMessageSent && onMessageSent();
        },
        onError(error) {
            catchError(error)
        }
    })

    const {
        handleSubmit,
        control,
        errors,
        clearErrors,
        setError,
    } = useForm({
        resolver: yupResolver(schema)
    });

    const onSubmit = ({ name, email, message }) => {
        setIsLoading(true);
        clearErrors()
        setErrorMessage('');

        if (authentified) {
            contactAuthentified({
                variables: {
                    message
                }
            })
        } else {
            contactPublic({
                variables: {
                    name,
                    email,
                    message
                },
                context: {
                    isAnonymous: true
                }
            })
        }
    }

    const onError = () => {
        setSubmitButtonState(setHasSubmitError)
    }

    return (
        <form onSubmit={handleSubmit(onSubmit, onError)}>

            <FormAlert>{errorMessage}</FormAlert>

            {!authentified &&
                <>
                    <Controller
                        control={control}
                        name="name"
                        defaultValue=""
                        render={({ onChange, value }) => (
                            <ControlText
                                onChange={onChange}
                                error={errors.name?.message}
                                type="text"
                                value={value}
                                placeholder="Tony Stark"
                                label="🤦 Votre prénom et nom"
                            />
                        )}
                    />

                    <Controller
                        control={control}
                        name="email"
                        defaultValue=""
                        render={({ onChange, value }) => (
                            <ControlText
                                onChange={onChange}
                                error={errors.email?.message}
                                type="text"
                                value={value}
                                placeholder="Si vous voulez qu'on vous réponde, faut renseigner une adresse email ..."
                                label="✉️ Votre adresse email"
                            />
                        )}
                    />
                </>
            }

            <Controller
                control={control}
                name="message"
                defaultValue=""
                render={({ onChange, value }) => (
                    <ControlTextarea
                        onChange={onChange}
                        error={errors.email?.message}
                        value={value}
                        label="✏️ Votre message"
                        placeholder="Hello !&#10;Votre application m'a l'air super !&#10;J'aimerai avoir plus d'informations&#10;Merci"
                    />
                )}
            />

            <FormSubmit isLoading={isLoading} hasSubmitError={hasSubmitError}>Envoyer 😘</FormSubmit>

        </form>
    )
}

ContactForm.propTypes = {
    onMessageSent: PropTypes.func,
    authentified: PropTypes.bool
}

export default ContactForm;