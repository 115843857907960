import React, { useRef } from "react";
import PropTypes from "prop-types";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const ControlTextarea = ({
  icon,
  error,
  help,
  label,
  value,
  placeholder,
  onChange,
  rows = 5,
  autoFocus = false,
}) => {
  const hasError = error && error.length > 0;
  const inputRef = useRef("");

  return (
    <div className="field">
      {label && <div className="label">{label}</div>}

      <div
        className={"control" + (icon ? " has-icons-left has-icons-right" : "")}
      >
        {((inputRef.current && inputRef.current.value.length > 0) ||
          (value && value.length > 0)) && (
          <div className="control-title">{placeholder}</div>
        )}

        {icon && (
          <span className="icon is-small is-left">
            <FontAwesomeIcon icon={icon} />
          </span>
        )}

        <textarea
          onChange={(e) => onChange(e)}
          ref={inputRef}
          className={"textarea is-medium" + (hasError ? " is-danger" : "")}
          placeholder={placeholder}
          autoFocus={autoFocus}
          value={value ?? ""}
          rows={rows}
        ></textarea>
      </div>

      {hasError && <p className="help is-danger">{error}</p>}

      {help && <p className="help has-text-grey-light">{help}</p>}
    </div>
  );
};

ControlTextarea.propTypes = {
  icon: PropTypes.any,
  help: PropTypes.string,
  label: PropTypes.string,
  error: PropTypes.string,
  rows: PropTypes.number,
  value: PropTypes.string,
  placeholder: PropTypes.string,
  autoFocus: PropTypes.bool,
  onChange: PropTypes.func,
};

export default ControlTextarea;
