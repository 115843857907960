import { setLang } from 'utils/lang'
import { setCssTheme } from 'utils/theme'

export const hasAccessToken = () => {

    return (localStorage.getItem('access-token') !== null)
}

export const setAccessToken = (token, tokenTtl = null) => {

    localStorage.setItem('access-token', token)
    localStorage.setItem('access-token-date', new Date().toString())

    if (tokenTtl) {
        localStorage.setItem('access-token-ttl', tokenTtl)
    }
}

export const accessTokenIsValid = () => {

    const accessTokenDate = localStorage.getItem('access-token-date');
    let accessTokenTtl = localStorage.getItem('access-token-ttl');

    if (!accessTokenDate || !accessTokenTtl) {
        return false;
    }

    const accessTokenDateDate = new Date(accessTokenDate);
    const now = new Date()

    // retrieve date diff in seconds
    const diff = (now.getTime() - accessTokenDateDate.getTime()) / 1000;

    return (diff < parseInt(accessTokenTtl))
}

export const setUserLoggedIn = (login, data) => {

    setAccessToken(data.token, data.token_ttl);

    if (data.lang) {
        setLang(data.lang)
    }
    if (data.theme) {
        setCssTheme(data.theme)
    }

    localStorage.setItem('refresh-token', data.refresh_token)
    localStorage.setItem('last-login', login)
}

export const deleteTokenStorage = () => {
    localStorage.removeItem('access-token');
    localStorage.removeItem('access-token-date');
    localStorage.removeItem('access-token-ttl');
    localStorage.removeItem('refresh-token');
}
