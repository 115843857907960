import React, { useState, useEffect, useContext } from "react";

import { useLocation } from "react-router-dom";
import { faBars, faMagnifyingGlass, faCalendarDays, faDumbbell } from "@fortawesome/free-solid-svg-icons";

import NavbarItem from "components/menu/NavbarItem";
import { UserContext } from "context/UserContext";

import { isAdminUrl } from "utils/app";

const toggleAdminMenu = () => {
    if (document.body.classList.contains("has-admin-menu")) {
        document.body.classList.remove("has-admin-menu");
    } else {
        document.body.classList.add("has-admin-menu");
    }
};

const NavbarBrand = () => {

    const location = useLocation()

    const [isAdminLocation, setIsAdminLocation] = useState(isAdminUrl())

    const { isConnected, clubCount } = useContext(UserContext)

    useEffect(() => {
        setIsAdminLocation(isAdminUrl())
    }, [location])

    return (
        <div className="navbar-brand">
            {isAdminLocation &&
                <NavbarItem
                    onClick={toggleAdminMenu}
                    icon={faBars}
                    mobileOnly={true}
                    title="header.menu"
                />
            }

            {isConnected && clubCount > 0 && (
                <>
                    <NavbarItem
                        title="header.sessions"
                        icon={faDumbbell}
                        url="my_sessions"
                        desktopOnly={true}
                    />
                    <NavbarItem
                        title={clubCount == 1 ? "header.club" : "header.clubs"}
                        icon={faCalendarDays}
                        url="clubs"
                        desktopOnly={true}
                    />
                </>
            )}

            {isConnected && clubCount <= 0 && (
                <NavbarItem
                    title={"header.onboard"}
                    url="onboard"
                />
            )}


            {isConnected && (
                <>
                    <NavbarItem
                        title="header.search_club"
                        icon={faMagnifyingGlass}
                        url="club_search"
                        desktopOnly={true}
                    />
                </>
            )}

        </div>
    );
};

export default NavbarBrand;
