import React from 'react'
import { Link } from 'react-router-dom';
import { getRoute } from 'utils/route';

const PageHomeHeader = () => {
    return (
        <>
            <div className="block">
                <h1 className="is-size-1">Monkey resa</h1>
                <h2 className="subtitle">Le logiciel de gestion de votre salle de sport</h2>

                <Link to={getRoute("signin")} className="button is-primary mr-3 mb-1">Connectez-vous</Link>
                <Link to={getRoute("signup")} className="button is-info">Inscrivez-vous</Link>
            </div>

            <div className="block">
                <figure className="image image is-3by1">
                    {/* https://www.istockphoto.com/fr/photo/cross-femme-fit-soulever-des-poids-lourds-dans-la-salle-de-gym-gm942286558-257521135?phrase=crossfit */}
                    <img src="img/crossfit-banner.jpg" />
                </figure>
            </div>
        </>
    )
}

export default PageHomeHeader;