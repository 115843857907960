import React from 'react';
import PropTypes from 'prop-types'

const PageHeader = ({ children }) => {
    return (
        <div className="page-header">
            <div className="is-size-1 is-size-4-mobile has-text-weight-semibold is-family-primary">{children}</div>
        </div>
    )
}

PageHeader.propTypes = {
    children: PropTypes.any
}

export default PageHeader;