import React from 'react'
import PropTypes from 'prop-types'

const FormAlert = ({children}) => {
    return (
        children != '' && <div className="notification is-warning">{children}</div>
    )
}

FormAlert.propTypes = {
    children: PropTypes.any
}

export default FormAlert