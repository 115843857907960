import { setContext } from "@apollo/client/link/context";
import { TokenRefreshLink } from "apollo-link-token-refresh";
import { ApolloLink } from "apollo-link";
import { createUploadLink } from "apollo-upload-client";
import { ApolloClient, createHttpLink, InMemoryCache } from "@apollo/client";

import { accessTokenIsValid } from "utils/auth";
import { getOptions } from "utils/fetch";

import config from "config/app";
import { routes } from "routes";

/**
 * Appolo RefreshLink middleware
 * Try to obtain, using refresh-token, a new access-token if expired
 */
const middlewareRefreshToken = () => {
  const refreshToken = localStorage.getItem("refresh-token");

  const body = {
    refresh_token: refreshToken,
  };

  return new TokenRefreshLink({
    accessTokenField: "access_token",
    isTokenValidOrUndefined: () => accessTokenIsValid(),
    fetchAccessToken: () => {
      // fetch a new access token
      return fetch(config.api_url + "/token/refresh", getOptions("POST", body));
    },
    handleResponse: () => (response) => {
      // handle and format response to match with handleFetch args
      return response.json().then((data) => {
        localStorage.setItem("refresh-token", data.refresh_token);
        return {
          access_token: data.token,
        };
      });
    },
    handleFetch: (accessToken) => {
      // set new access token
      localStorage.setItem("access-token", accessToken);
      localStorage.setItem("access-token-date", new Date());
    },
    handleError: () => {
      window.location.href = routes.signin;
    },
  });
};

const middlewareHttpLink = () => {
  const httpOptions = {
    uri: config.graphql_url,
  };

  return ApolloLink.split(
    (operation) => operation.getContext().hasUpload,
    createUploadLink(httpOptions),
    createHttpLink(httpOptions)
  );
};

const middleWareBearerLink = (isAnonymous) => {
  return setContext((_, { headers }) => {
    if (isAnonymous) {
      return headers;
    }

    // get the authentication token from local storage if it exists
    const token = localStorage.getItem("access-token");

    var headersFinal = { ...headers };

    // Add Authorization token if not exists
    if (!headersFinal["Authorization"]) {
      headersFinal = {
        ...headersFinal,
        Authorization: token ? `Bearer ${token}` : "",
      };
    }

    return { headers: { ...headersFinal } };
  });
};

const getApolloLink = ({ isTest, isAnonymous }) => {
  const refreshToken = localStorage.getItem("refresh-token");
  
  if (isTest || !refreshToken || refreshToken === undefined || refreshToken === 'undefined') {
    return ApolloLink.from([
      middleWareBearerLink(isAnonymous),
      middlewareHttpLink(),
    ]);
  } else {
    return ApolloLink.from([
      middlewareRefreshToken(),
      middleWareBearerLink(isAnonymous),
      middlewareHttpLink(),
    ]);
  }
};

// create an apollo local cache
import { cache } from "cache";
const apolloCache = new InMemoryCache({
  typePolicies: {
    Query: {
      fields: {
        Sessions: {
          keyArgs: false,
          merge(_, incoming) {
            return incoming;
          },
        },
        cache: {
          read() {
            return cache();
          },
        },
      },
    },
  },
});

const getApolloClient = () => {
  return new ApolloClient({
    link: new ApolloLink((operation) => {
      const options = {
        isTest: operation.getContext().isTest,
        isAnonymous: operation.getContext().isAnonymous,
      };
      return getApolloLink(options).request(operation);
    }),
    cache: apolloCache,
    shouldBatch: true,
  });
};

export default getApolloClient;
