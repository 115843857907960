import i18n from "i18next"
import detectBrowserLanguage from 'detect-browser-language'

/** 
 * Detect if string is surrounding with % characters and translate it content if it is true
 */
export const translate = (t, entry) => {
    if (entry && entry.charAt(0) == '%' && entry.slice(-1) == '%') {
        entry = t(entry.slice(1, -1))
    }
    return entry;
}

export const getLang = () => {

    var lang = localStorage.getItem('i18nextLng')
    
    if (!lang) {
        lang = detectBrowserLanguage()
    }

    if (lang.indexOf('-') >= 0) {
        lang = lang.split('-')[0]
    }
    
    return lang;
}
export const setLang = (lang) => {
    localStorage.setItem('i18nextLng', lang)
    i18n.changeLanguage(lang)
}
