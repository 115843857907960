import app_config from 'config/app'

export const getOptions = (method, body) => {

    return {
        method: method,
        body: JSON.stringify(body),
        headers: {
            'Content-Type': 'application/json'
        },
        credential: 'same-origin'
    }
}

export const fetchApi = (path, method, body) => {
    return new Promise((resolve, reject) => {
        fetch(app_config.api_url + path, getOptions(method, body))
            .then((response) => resolve(response))
            .catch((response) => reject(response))
    });
}