import React from "react";

const PageHomeFooter = () => {
    return (
        <footer className="footer">
            <div className="content">
                <div className="columns">
                    <div className="column is-two-thirds">
                        <p><a href="#">Mentions légales</a> | <a href="#contact-block">Contactez-nous</a></p>
                    </div>
                    <div className="column has-text-right has-text-left-mobile">
                        <p>© 2022 Monkey Fox SAS</p>
                    </div>
                </div>
            </div>
        </footer>
    )
}

export default PageHomeFooter;