import React, { useState, useContext, useEffect } from 'react'
import PropTypes from 'prop-types'
import { useLocation } from "react-router-dom";

import NavbarBrand from 'structure/menu/NavbarBrand'
import NavbarEnd from 'structure/menu/NavbarEnd'
import { MenuContext } from 'context/MenuContext'

import { UserContext } from 'context/UserContext'
import { isAdminUrl } from "utils/app";

const Navbar = () => {

    const location = useLocation()

    const { hasWorkspace } = useContext(UserContext);

    const [isActive, setIsActive] = useState(false)
    const menuState = { isActive, setIsActive };

    const [isAdminLocation, setIsAdminLocation] = useState(isAdminUrl())

    useEffect(() => {
        setIsAdminLocation(isAdminUrl())
    }, [location])

    const classAdmin = (isAdminLocation && hasWorkspace) ? "" : "is-hidden-mobile";

    return (
        <MenuContext.Provider value={menuState}>
            <div id="app-navbar" className={"navbar is-dark is-fixed-top " + classAdmin} role="navigation" aria-label="main navigation">
                <NavbarBrand />
                <NavbarEnd />
            </div>
        </MenuContext.Provider>
    )
}

Navbar.propTypes = {
    isConnected: PropTypes.bool,
}

export default Navbar;