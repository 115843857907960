import React from 'react';
import PropTypes from 'prop-types'

const PageBody = ({ children }) => {
    return (
        <div className="page-body">
            {children}
        </div>
    )
}

PageBody.propTypes = {
    children: PropTypes.any
}

export default PageBody;