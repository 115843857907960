import React from 'react'
import PropTypes from 'prop-types'

const FormSubmit = ({isLoading, isDisabled, isSubmitted, hasSubmitError, classSize = 'is-medium', children}) => {

    const classLoading = isLoading ? ' is-loading' : ''

    return (
        <div className="field">
            <button className={`button button-submit is-primary is-fullwidth is-primary ${classSize} ${classLoading}`} type="submit" disabled={(isLoading ||isDisabled) && 'disabled'}>
                <div className={'submit-overlay' +(isSubmitted ? ' is-submitted': '') + (hasSubmitError ? ' has-submit-error': '')}></div>
                <span>{children}</span>
            </button>
        </div>
    )
}

FormSubmit.propTypes = {
    isLoading: PropTypes.bool,
    isDisabled: PropTypes.bool,
    isSubmitted: PropTypes.bool,
    hasSubmitError: PropTypes.bool,
    classSize: PropTypes.string,
    children: PropTypes.any
};

export default FormSubmit