import React from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next';

import { translate } from 'utils/lang'

const FormPanel = ({title, children}) => {

    const { t } = useTranslation();

    title = translate(t, title)


    return (
        <div className="form-container">
            <div className="panel is-info">
                <div className="panel-heading">{title}</div>
                <div className="panel-block panel-block-plain">
                    {children}
                </div>
            </div>
        </div>

    )
}

FormPanel.propTypes = {
    title: PropTypes.string,
    children: PropTypes.any

}
export default FormPanel