import { gql } from '@apollo/client';

export const CONTACT_PUBLIC = gql`
    mutation ContactPublic($name: String, $email: String, $message: String!) {
        ContactPublic(name: $name, email: $email, message: $message) 
    }
`;


export const CONTACT_AUTHENTIFIED = gql`
    mutation ContactAuthentified($message: String!) {
        ContactAuthentified(message: $message) 
    }
`;