export const setSubmitButtonState = (stateFn) => {
    stateFn(true)
    setTimeout(() => stateFn(false), 2000)
}


/**
 * According to a list of items structured as [{value:, label:}]
 * check if list contain only one element and return it
 * Usefull to have a ControlSelect already selected with the unique list element
 */
export const getControlSelectDefaultValue = (items) => {
    if (items.length == 1) {
      return items[0].value;
    }
    return null;
  }
  