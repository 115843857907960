import React, { useRef, useEffect, useState, useContext } from 'react'
import { useQuery } from '@apollo/client'

import LangItems from 'structure/menu/LangItems'
import NavbarItem from 'components/menu/NavbarItem';
import { GET_CACHE } from 'cache'
import { MenuContext } from 'context/MenuContext';

const MenuDefault = () => {

    const menuRef = useRef(null)
    const [currentLang, setCurrentLang] = useState('');
    const { isActive, setIsActive } = useContext(MenuContext)

    const { data } = useQuery(GET_CACHE)

    useEffect(() => {
        if (data) {
            setCurrentLang(data.cache.lang)
        }
    }, [data])

    return (
        <>
            <NavbarItem
                title="header.signin"
                url="signin"
            />

            <NavbarItem
                title="header.signup"
                url="signup"
            />

            <div ref={menuRef} className={'navbar-item has-dropdown' + (isActive ? ' is-active' : '')}>
                <a className="navbar-link" onClick={() => setIsActive(current => !current)}>
                    {currentLang}
                </a>
                <div className="navbar-dropdown">
                    <LangItems
                        onClick={() => setIsActive(false)}
                        isConnected={false}
                    />
                </div>
            </div>

        </>
    )
}

export default MenuDefault