import * as yup from "yup";

const getSchema = (authentified) => {
    if (authentified) {
        return yup.object().shape({
            message:
                yup.string()
                    .required("Ce champ est requis")
        });        
    }

    return yup.object().shape({
        name:
            yup.string()
                .required("Ce champ est requis"),
        email:
            yup.string()
                .required("Ce champ est requis")
                .email("Merci de renseigner une adresse email"),
        message:
            yup.string()
                .required("Ce champ est requis")
    });
}

export default getSchema;