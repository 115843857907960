import { routes } from 'routes'

export const getRoute = (uid, args) => {

    if (!routes[uid]) {
        return null
    }

    let route = routes[uid]

    if (args) {
        for (const [key, value] of Object.entries(args)) {
            route = route.replace(`:${key}`, `${value}`)
        }
    }

    return route
}