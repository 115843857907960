import { isValidPassword } from 'utils/password'
import * as yup from "yup";

const getSchema = (t) => {

    return yup.object().shape({
        email:
            yup.string()
                .required(t('errors:forms.signup.fields.email.-100'))
                .email(t('errors:forms.signup.fields.email.-200')),

        firstname:
            yup.string()
                .required(t('errors:forms.signup.fields.firstname.-100')),

        lastname:
            yup.string()
                .required(t('errors:forms.signup.fields.lastname.-100')),

        password:
            yup.string()
                .required(t('errors:forms.signup.fields.password.-100'))
                .test('isValidPassword', t('errors:forms.signup.fields.password.-3000'), value => isValidPassword(value)),

        password_confirmation:
            yup.string()
                .required(t('errors:forms.signup.fields.password.-100'))
                .oneOf([yup.ref('password'), null], t('errors:forms.signup.fields.password.-3001')),

        timezone:
            yup.string()
                .required(t('errors:forms.signup.fields.timezone.-100')),
    });
}

export default getSchema;
