import React from 'react';

export const UserState = {
    isConnected: false,
    setIsConnected: (isConnected) => isConnected,

    picture: null,
    setPicture: (picture) => picture,

    firstname: null,
    setFirstname: (firstname) => firstname,

    hasWorkspace: null,
    setHasWorkspace: (hasWorkspace) => hasWorkspace,

    clubCount: null,
    setClubCount: (clubCount) => clubCount,
}

export const UserContext = React.createContext(UserState)