import React, { useContext } from "react";

import config from "config/app";
import { UserContext } from "context/UserContext";
import { Link } from "react-router-dom";
import { getRoute } from "utils/route";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCog } from '@fortawesome/free-solid-svg-icons'

const MenuUser = () => {

    const { firstname, picture, hasWorkspace } = useContext(UserContext)

    return (
        <>
            {hasWorkspace && <Link className="navbar-item" to={getRoute("admin_home")}>
                <span className="mr-2">
                    <FontAwesomeIcon icon={faCog} />
                </span>
                Admin
            </Link>}

            <Link className="navbar-item" to={getRoute("profil")}>
                <div className="media">
                    <figure className="media-left">
                        <div className="image is-32x32">
                            <img className="is-rounded" src={config.api_url + "/" + picture} />
                        </div>
                    </figure>
                    <div className="media-content">
                        <div className="content">
                            <p className="mt-1">{firstname}</p>
                        </div>
                    </div>
                </div>
            </Link>

        </>
    );
};

export default MenuUser;
