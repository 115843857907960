import React, { useContext, useEffect, useState } from 'react'
import { MessagePopinContext } from 'context/MessagePopinContext';

const displayDuration = 2000
var displayTimer = null

function MessagePopin() {

    const [visible, setVisible] = useState('')
    const context = useContext(MessagePopinContext)

    useEffect(() => {
        if (context.state.message !== '') {
            setVisible('popin-visible')
            displayTimer = setTimeout(() => setVisible('popin-hidden'), displayDuration)
        }
    }, [context.state])

    const hidePopin = () => {
        setVisible('popin-hidden')
        if (displayTimer) {
            clearTimeout(displayTimer)
            displayTimer = null
        }
    }

    return (
        <div className={"message-popin-container " + visible}>
            <div onClick={() => hidePopin()} className={"message-popin has-background-" + context.state.type}>
                <div className="message-popin-content p-5">
                    {context.state.message}
                </div>
            </div>
        </div>
    )
}

export default MessagePopin