import React from 'react';
import { useTranslation } from 'react-i18next';

import Page from 'structure/Page'

import PageHomeHeader from './PageHomeHeader';
// import PageHomeCards from './PageHomeCards';
// import PageHomeFeatures from './PageHomeFeatures';
import PageHomeContact from './PageHomeContact';
import PageHomeFooter from './PageHomeFooter';

const PageHome = () => {

    const { t } = useTranslation(['translation', 'pages']);

    return (
        <>
            <Page metaTitle={t("pages:home.meta.title")}>

                <PageHomeHeader />


                <PageHomeContact />

            </Page>

           <PageHomeFooter />
        </>
    )
}

export default PageHome;