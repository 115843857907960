export const setCssTheme = (theme) => {
    if (theme === 'auto') {
        theme = getDetectedTheme()
    }

    var el = document.getElementById('css-theme')
    if (el) {
        el.remove()
    }

    var head = document.getElementsByTagName('head')[0];
    var link = document.createElement('link');

    link.id = 'css-theme';
    link.rel = 'stylesheet';
    link.type = 'text/css';
    link.href = '/themes/' + theme + '.css'
    link.media = 'all';

    head.appendChild(link);

    localStorage.setItem('last-theme', theme)
}

export const getDetectedTheme = () => {
    /*if (window.matchMedia("(prefers-color-scheme: dark)").matches) {
        return "dark";
    }*/
    return isJourney() ? "light" : "dark";
}

export const isJourney = () => {
    var now = new Date();
    var nightDate = new Date();
    var morningDate = new Date();

    nightDate.setHours(20, 0, 0);
    morningDate.setHours(6, 0, 0);

    return (now >= morningDate && now <= nightDate);
}