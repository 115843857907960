export const routes = {
    "signup": "/signup",
    "signup_done": "/signup/done",
    "signup_validate": "/signup/validate/:uid/:hash",
    "signup_validate_done": "/signup/validate/done",
    "signup_finalize": "/signup/finalize/:uid/:hash/:token",
    "signup_finalize_done": "/signup/finalize/done",
    "signin": "/signin",
    "signout": "/signout",
    "profil": "/profil/infos",
    "profil_notifications_email_reminder": "/profil/notifications/email_reminder/:status",
    "my_sessions": "/profil/sessions",
    "package_user": "/profil/package/:package_user_id",
    "onboard": "/onboard",
    "contact": "/contact",

    "password_forgot": "/password/forgot",
    "password_forgot_done": "/password/forgot/done",
    "password_reset": "/password/reset/:uid/:hash/:token",
    "password_reset_done": "/password/reset/done",

    "home": "/",
    "clubs": "/clubs",
    "club": "/club/:slug",
    "club_creation": "/club_creation",
    "club_search": "/club_search",
    "session": "/session/:uid",

    "error_404": "/error/404",
    
    "admin_home": "/admin",
    "admin_home_persist": "/admin?persist=true",

    "admin_club_home": "/admin/club/:club_id",
    "admin_company": "/admin/club/:club_id/company",
    "admin_rooms": "/admin/club/:club_id/rooms",
    "admin_types": "/admin/club/:club_id/types",
    "admin_plannings": "/admin/club/:club_id/plannings",
    "admin_member_moderate": "/admin/member/:member_id/:status",
    "admin_members": "/admin/club/:club_id/members",
    "admin_member": "/admin/club/:club_id/member/:member_id",
    "admin_package_user": "/admin/club/:club_id/member/:member_id/package/:package_user_id",
    "admin_packages": "/admin/club/:club_id/packages",
    "admin_config": "/admin/club/:club_id/config",
    "admin_absences": "/admin/club/:club_id/absences",

    "admin_club_create": "/admin/club/create",
    "admin_room_create": "/admin/club/:club_id/room/create",
    "admin_type_create": "/admin/club/:club_id/type/create",
    "admin_package_create": "/admin/club/:club_id/package/create",
    "admin_member_create": "/admin/club/:club_id/member/create",

    "admin_club_edit": "/admin/club/:club_id/edit",
    "admin_room_edit": "/admin/club/:club_id/room/:room_id/edit",
    "admin_type_edit": "/admin/club/:club_id/type/:type_id/edit",
    "admin_package_edit": "/admin/club/:club_id/package/:package_id",
    "admin_member_edit": "/admin/club/:club_id/member/:member_id/edit"
}

export const routesExclusions = [
    routes.signin,
    routes.signout,
    routes.signup,
    routes.signup_done,
    routes.signup_validate,
    routes.signup_validate_done,
    routes.signup_finalize,
    routes.signup_finalize_done,
    routes.password_forgot,
    routes.password_forgot_done,
    routes.password_reset,
    routes.password_reset_done,
]
