import React, { useRef, useState } from "react";
import PropTypes from "prop-types";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye } from "@fortawesome/free-solid-svg-icons";

const TYPE_PASSWORD = "password";
const TYPE_TEXT = "text";

const ControlText = ({
    icon,
    error,
    type,
    label,
    help,
    tooltip,
    value,
    placeholder,
    onChange,
    leftAddons,
    leftAddonsClass,
    rightAddons,
    rightAddonsClass,
    disabled = false,
    autoFocus = false,
}) => {

    const hasError = error && error.length > 0;
    const inputRef = useRef();
    const [inputType, setInputType] = useState(type);

    const toggleInputType = () =>
        setInputType(inputType == TYPE_PASSWORD ? TYPE_TEXT : TYPE_PASSWORD);

    return (

        <div className="field">
            {label && tooltip && (
                <label data-tooltip={tooltip} className="label">{label}</label>
            )}

            {label && !tooltip && (
                <label className="label">{label}</label>
            )}

            <div className={"field " + (leftAddons || rightAddons ? "has-addons" : "")}>
                {leftAddons && (
                    <div className="control">
                        <a className={"button is-medium " + leftAddonsClass}>
                            {leftAddons}
                        </a>
                    </div>
                )}

                <div className={"control is-expanded" + (icon ? " has-icons-left has-icons-right" : "")}>

                    {((inputRef.current && inputRef.current.value.length > 0) ||
                        (value && value.length > 0)) && (
                            <div className="control-title">{placeholder}</div>
                        )}

                    {icon && (
                        <span className="icon is-small is-left">
                            <FontAwesomeIcon icon={icon} />
                        </span>
                    )}

                    <input
                        onChange={(e) => onChange(e)}
                        ref={inputRef}
                        className={"input is-medium" + (hasError ? " is-danger" : "")}
                        type={inputType}
                        value={value ?? ""}
                        placeholder={placeholder}
                        autoFocus={autoFocus}
                        disabled={disabled}
                    />

                    {type == TYPE_PASSWORD && (
                        <div
                            onClick={() => toggleInputType()}
                            className={
                                "password-input-toggler icon is-right " +
                                (inputType == TYPE_TEXT
                                    ? "has-text-success"
                                    : "has-text-grey-lighter")
                            }
                        >
                            <FontAwesomeIcon icon={faEye} />
                        </div>
                    )}
                </div>

                {rightAddons && (
                    <div className="control">
                        <a className={"button is-medium " + rightAddonsClass}>
                            {rightAddons}
                        </a>
                    </div>
                )}

            </div>

            {hasError && <p className="help is-danger">{error}</p>}
            
            {help && <p className="help has-text-grey-light">{help}</p>}
        </div>
    );
};

ControlText.defaultProps = {
    rightAddonsClass: "is-static",
    disabled: false,
    autoFocus: false
}

ControlText.propTypes = {
    icon: PropTypes.any,
    help: PropTypes.string,
    tooltip: PropTypes.string,
    label: PropTypes.string,
    error: PropTypes.string,
    type: PropTypes.string,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    placeholder: PropTypes.string,
    autoFocus: PropTypes.bool,
    disabled: PropTypes.bool,
    onChange: PropTypes.func,
    leftAddons: PropTypes.string,
    leftAddonsClass: PropTypes.string,
    rightAddons: PropTypes.string,
    rightAddonsClass: PropTypes.string
};

export default ControlText;
