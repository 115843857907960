import * as yup from "yup";

const getSchema = (t) => {
    return yup.object().shape({
        login:
            yup.string()
                .required(t('errors:forms.signin.fields.login.-100'))
                .email(t('errors:forms.signin.fields.login.-200')),
        password:
            yup.string()
                .required(t('errors:forms.signin.fields.password.-100'))
    });
}

export default getSchema;