import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { useQuery, useMutation } from '@apollo/client'
import { useTranslation } from 'react-i18next';
import { ME_UPDATE_LANG } from 'graphql/mutation/me-lang'
import { setLang } from 'utils/lang'
import { GET_CACHE } from 'cache'

const getClass = (currentLang, lang) => (currentLang === lang ? 'navbar-item has-text-weight-bold' : 'navbar-item')

const LangItems = ({ onClick, isConnected }) => {

    const { t } = useTranslation();
    const [currentLang, setCurrentLang] = useState('');

    const { data } = useQuery(GET_CACHE)
    const [updateLang] = useMutation(ME_UPDATE_LANG)
    
    useEffect(() => {
        if (data) {
            setCurrentLang(data.cache.lang)
        }
    }, [data])

    const handleOnClick = (lang) => {
        setLang(lang)        
        isConnected && updateLang({ variables: { lang }})
        onClick && onClick()
    }
    return (
        <>
            <a onClick={() => handleOnClick('fr')} className={getClass(currentLang, 'fr')}>{t('header.lang-fr')}</a>
            <a onClick={() => handleOnClick('en')} className={getClass(currentLang, 'en')}>{t('header.lang-en')}</a>
        </>
    )
}

LangItems.propTypes = {
    onClick: PropTypes.func,
    isConnected: PropTypes.bool
}

export default LangItems