import React from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next';
import { Link } from "react-router-dom"
import { translate } from 'utils/lang'

const FormLinks = ({ link1, txt1, link2, txt2 }) => {

    const { t } = useTranslation();

    txt1 = translate(t, txt1)
    txt2 = translate(t, txt2)

    return (
        <div className="field">
            <div className="columns is-mobile">
                <div className="column">
                    <small className="ml-4">
                        <Link to={link1}>{txt1}</Link>
                    </small>
                </div>

                <div className="column">
                    <small className="is-pulled-right mr-4">
                        <Link to={link2}>{txt2}</Link>
                    </small>
                </div>
            </div>
        </div>

    )
}

FormLinks.propTypes = {
    link1: PropTypes.string,
    txt1: PropTypes.string,
    link2: PropTypes.string,
    txt2: PropTypes.string
}

export default FormLinks