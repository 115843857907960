import React from 'react'
import PropTypes from 'prop-types'

const ContentCenter = ({children}) => {
    return (
        <div className="align-items-full-center-container">{children}</div>
    )
}

ContentCenter.propTypes = {
    children: PropTypes.any
}

export default ContentCenter