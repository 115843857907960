import React from 'react'
import PropTypes from 'prop-types'

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const ItemIcon = ({ icon }) => {
    return (
        <span className="icon">
            <FontAwesomeIcon icon={icon} />
        </span>
    )
}

ItemIcon.defaultProps = {
    mobileOnly: false
}

ItemIcon.propTypes = {
    icon: PropTypes.any
}

export default ItemIcon;