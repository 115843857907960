import React from 'react'
import Navbar from 'structure/menu/Navbar'
import config from "config/app";

const Header = () => {
    return (
        <>
            <Navbar />
            {config?.service_message && 
            <div className="has-background-danger p-4 has-text-primary-light" dangerouslySetInnerHTML={{
                __html: config.service_message
            }}></div>}
        </>
    )
}

export default Header;
