import React, { useContext } from 'react'
import PropTypes from 'prop-types'
import MenuDefault from 'structure/menu/MenuDefault'
import MenuUser from 'structure/menu/MenuUser'
import { MenuContext } from 'context/MenuContext'
import { UserContext } from 'context/UserContext'

const NavbarEnd = () => {

    const { isActive } = useContext(MenuContext)
    const { isConnected } = useContext(UserContext)

    return (
        <div id="navbar-end" className={"navbar-menu" + (isActive ? " is-active" : "")}>
            <div className="navbar-end">
                {isConnected ? <MenuUser /> : <MenuDefault />}
            </div>
        </div>
    )
}

NavbarEnd.propTypes = {
    isActive: PropTypes.bool
}
export default NavbarEnd;