import { gql } from '@apollo/client';

export const SIGNIN = gql`
    mutation Signin($login: String!, $password: String!) {
        Signin(login: $login, password: $password) {
            token
            token_ttl
            refresh_token
            firstname
            lastname
            picture
            lang
            theme
            has_workspace
            Clubs {
                id
                slug
                name
            }
        }
    }  
`;

export const SIGNIN_FACEBOOK = gql`
    mutation LoginFacebook($accessToken: String!) {
        LoginFacebook(accessToken: $accessToken) {
            token
            token_ttl
            refresh_token
            picture
            lang
            theme
        }
    }  
`;