import { gql } from '@apollo/client';

export const SIGNUP = gql`
    mutation Signup(
        $email: String!, 
        $firstname: String!, 
        $lastname: String!, 
        $timezone: String!,
        $password: String!
    ) {
        Signup(
            email: $email, 
            firstname: $firstname, 
            lastname: $lastname, 
            timezone: $timezone,
            password: $password
        )
    }  
`;


export const SIGNUP_VALIDATE = gql`
    mutation SignupValidate(
        $uid: String!, 
        $hash: String!
    ) {
        SignupValidate(
            uid: $uid, 
            hash: $hash
        )
    }  
`;
