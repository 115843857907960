import React, { useState } from 'react'
import { useTranslation } from 'react-i18next';
import ContactForm from 'forms/Contact/ContactForm';

const PageHomeContact = () => {

    const { t } = useTranslation(['pages'])

    const [messageSent, setMessageSent] = useState(false);

    return (
        <div id="contact-block" className="block">
            <div className="box">
                <h3 className="is-size-3">Contactez-nous</h3>
                <p className="block">Vous souhaitez plus d&apos;information, nous dire que vous nous aimez beaucoup, passez donc par ce formulaire et on vous répondra après l&apos;entrainement</p>

                {!messageSent &&
                    <ContactForm onMessageSent={() => setMessageSent(true)} />
                }

                {messageSent &&
                    <article className="message is-success">
                        <div className="message-body">
                            <h4 className="is-size-4">{t("pages:contact.success_title")}</h4>
                            <p>{t("pages:contact.success_message")}</p>
                        </div>
                    </article>
                }

            </div>
        </div>
    )
}

export default PageHomeContact;