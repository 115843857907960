/** 
 * Catch API form validation errors 
 * error: the return API error response
 * t: useTranslation hook (don't forget to instanciate 'errors' with useTranslation(['translation', 'errors']))
 * errorPath: json path to error prefixed with "errors:forms."
 * defaultMessagePath: json path to message prefixed with "errors:forms."
 * setError: function returned by useForm hook
 * setMessage: function to display message within form (returned by useState)
 * */
export const catchFormErrors = ({ error, t, errorPath, defaultMessagePath, setError, setMessage }) => {
    
    let flagMessage = false
    
    error.graphQLErrors.map(item => {

        const field = item.message

        var path = 'errors:forms.' + errorPath + ".fields." + field + '.' + item.code
        var message = t(path)
    
        if (message && ('errors:'+message) !== path) {
            setError(field, {
                type: 'manual',
                message
            })
        } else {
            path = 'errors:forms.' + errorPath + ".messages." + field
            message = t(path)

            if (message && ('errors:'+message) !== path) {
                flagMessage = true
                setMessage && setMessage(message)
            } else {
                path = 'errors:messages.' + field
                message = t(path)
                
                if (message && ('errors:'+message) !== path) {
                    flagMessage = true
                    setMessage && setMessage(message)
                }
            }
        }
    });

    if (!flagMessage && defaultMessagePath) {
        setMessage && setMessage(t(defaultMessagePath))
    }
}

export const catchError = ({ t, path, fields, error, setMessage, setError }) => {

    error.graphQLErrors.map(item => {

        let found = false

        // if error has a code
        if (item.code) {
            // retrieve translation according to path + code
            let message = t(path + '.error' + item.code)
            // if message found
            if (message) {
                // and fields defined
                if (fields && setError) {
                    for (const field in fields) {
                        // if field codes match with current error code
                        if (fields[field].indexOf(item.code) >= 0) {
                            // set error message to related field
                            setError(field, {
                                type: 'manual',
                                message
                            })
                            found = true
                        }
                    }
                }
                // if no field have been found, set global message
                if (!found && setMessage) {
                    setMessage(message)
                }
            }
        } else if (item.message && setMessage) {
            // not error code but message, set global message 
            setMessage(item.message)
        }
    })
}