import React from 'react';

import ContentCenter from 'structure/ContentCenter'
import { SigninForm } from 'forms/Signin/SigninForm'
import { useTranslation } from 'react-i18next';
import { Helmet } from 'react-helmet-async';

const PageSignin = () => {

    const { t } = useTranslation('pages')

    return (
        <>
            <Helmet>
                <title>{t("pages:signin.meta.title")}</title>
            </Helmet>
            <ContentCenter>
                <SigninForm />
            </ContentCenter>
        </>
    )
}

export default PageSignin