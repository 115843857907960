import React, { useRef } from "react";
import PropTypes from "prop-types";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const ControlSelect = ({
  icon,
  name,
  error,
  help,
  label,
  value,
  values,
  placeholder,
  onChange,
  register,
  autoFocus = false
}) => {
  const hasError = error && error.length > 0;
  const inputRef = register ? register : useRef("");

  return (
    <div className="field">
      {label && <div className="label">{label}</div>}
      <div className={"control" + (icon ? " has-icons-left" : "")}>
        {icon && (
          <span className="icon is-small is-left">
            <FontAwesomeIcon icon={icon} />
          </span>
        )}

      <div className="control-title">{placeholder}</div>

        <div className="select is-fullwidth mb-3">
          <select
            name={name}
            onChange={(e) => onChange(e)}
            defaultValue={value ?? ""}
            ref={inputRef}
            className={"input is-medium" + (hasError ? " is-danger" : "")}
            autoFocus={autoFocus}
          >
            <option value="">{placeholder}</option>
            {values.map((item, index) => {
              const itemValue = item.value ? item.value : item;
              const itemLabel = item.label ? item.label : item;
              return (
                <option
                  key={index}
                  value={itemValue}
                >
                  {itemLabel}
                </option>
              );
            })}
          </select>
        </div>
      </div>

      {hasError && <p className="help is-danger">{error}</p>}

      {help && <p className="help has-text-grey-light">{help}</p>}
    </div>
  );
};

ControlSelect.propTypes = {
  icon: PropTypes?.any,
  name: PropTypes.string,
  help: PropTypes.string,
  label: PropTypes.string,
  error: PropTypes.string,
  type: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  values: PropTypes.arrayOf(PropTypes.any),
  placeholder: PropTypes.string,
  autoFocus: PropTypes.bool,
  onChange: PropTypes.func,
  register: PropTypes.any
};

export default ControlSelect;
