import moment from "moment";

export const isJson = (str) => {
    try {
        JSON.parse(str);
    } catch (e) {
        return false;
    }
    return true;
}
export const hasUpperCase = (str) => {
    return /[A-Z]/.test(str);
};
export const hasLowerCase = (str) => {
    return /[a-z]/.test(str);
};
export const hasNumber = (str) => {
    return /[0-9]/.test(str);
};
export const hasLength = (str, length) => {
    return str.length >= length;
};
export const nl2br = (str) => {
    return str.replace(/(?:\r\n|\r|\n)/g, "<br />");
};

export const nullIfEmpty = (value) => {
    return value === "" ? null : value;
};

export const intOrNull = (value) => {
    if (typeof (value) == 'number') {
        return value;
    }
    if (isNaN(value)) {
        return null;
    }
    return parseInt(value);
}

export const floatOrNull = (value) => {
    if (typeof (value) == 'number') {
        return value;
    }
    if (isNaN(value) || value === '' || value === undefined) {
        return null;
    }
    return parseFloat(value);
}

export const intOrZero = (value) => {
    if (typeof (value) == 'number') {
        return value;
    }
    if (isNaN(value) || value === '' || value === undefined || value === null) {
        return 0;
    }
    return parseInt(value);
}

export const floatOrZero = (value) => {
    if (typeof (value) == 'number') {
        return value;
    }
    if (isNaN(value) || value === '' || value === undefined) {
        return 0;
    }
    return parseFloat(value);
}

export const dateOrNull = (value) => {
    return value === "" || value === null
        ? null
        : moment(value).format("YYYY-MM-DD");
};

export const datetimeOrNull = (value) => {
    return value === "" || value === null
        ? null
        : moment(value).format("YYYY-MM-DD HH:mm:ss");
};

export const fill2Zero = (value) => ("00" + String(value)).slice(-2);
