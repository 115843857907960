import { makeVar } from "@apollo/client";
import { gql } from '@apollo/client'
import { getLang } from 'utils/lang'
import { getDetectedTheme } from "utils/theme";

// initial cache values
export const cache = makeVar({
    isConnected: false,
    firstname: '',
    picture: '',
    lang: getLang(),
    hasWorkspace: false,
    clubCount: 0,
    theme: getDetectedTheme()
});

export const GET_CACHE = gql`
    query GetCache {
        cache @client { 
            isConnected
            firstname
            picture
            lang
            theme
            hasWorkspace
            clubCount
        }
    }
`
